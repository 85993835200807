const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  data: () => ({
    textRules: {
      required: (v) => !!v || 'Field Required',
      fourNumbers: (v) => v.length == 4 || "Please enter 4 numbers"
    },
    emailRules: {
      valid: (v) => emailRegex.test(v) || 'Please enter a valid email',
      secondary: (v) =>
        !v ||
        emailRegex.test(v) ||
        'Please enter a valid email',
    },
    phoneRules: {
      tenNumbers: (v) => v.length == 10 || "Please enter 10 numbers"
    },
    checkboxRules: {
      required: (v) => !!v || 'Field Required',
    },
    radioRules: {
      requiredBoolean: (v) => !!v || v === false || 'Field Required'
    }
  })
}